import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import { globalWindow } from '@emico-utils/ssr-utils'
import { getAllStoreViews } from '@emico-utils/storeview-manager'
import styled from '@emotion/styled'
import { t } from '@lingui/macro'

import { PinIcon } from '../icons/PinIcon'
import theme from '../theme'

const StoreViewSelect = styled.select`
  /* all: unset; */
  appearance: none;
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.md};
  padding-right: 42px;
  background-color: transparent;
  border: none;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSI5IiBmaWxsPSJub25lIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMSAyYTEgMSAwIDAgMSAxLjY0LS43N0w4IDUuNzFsNS4zNy00LjMyYTEgMSAwIDAgMSAxLjQxLjE1QTEgMSAwIDAgMSAxNC42MyAzbC02IDQuODNhMSAxIDAgMCAxLTEuMjcgMGwtNi01QTEgMSAwIDAgMSAxIDJaIi8+PC9zdmc+');
  background-position: calc(100% - 20px) 8px;
  background-repeat: no-repeat;
  width: auto;
`

const Pin = styled(PinIcon)`
  color: ${theme.colors.white};
  margin-right: ${theme.spacing.sm};
`

const Label = styled.label`
  margin: 0;
`

interface StoreSwitcherProps {
  className?: string
}

export const StoreSwitcher = ({ className }: StoreSwitcherProps) => {
  const originalStoreViews = getAllStoreViews()
  const activeStoreView = useActiveStoreView()
  const defaultStoreViewCode = originalStoreViews.find(
    (storeview) => storeview.code === activeStoreView.code,
  )?.code

  const labels: Record<string, string> = {
    be_nl: t`België (NL)`,
    be_fr: t`Belgique (FR)`,
    cz: t`Česká republika`,
    dk: t`Danmark`,
    de: t`Deutschland`,
    es: t`España`,
    fr: t({ message: 'France', context: 'store.switcher.france' }),
    ie: t({ message: 'Ireland', context: 'store.switcher.ireland' }),
    it: t`Italia`,
    nl: t`Nederland`,
    no: t`Norge`,
    at: t`Österreich`,
    pl: t`Polska`,
    se: t`Sverige`,
    uk: t`United Kingdom`,
    us: t`United States`,
    // ch_de: t`Schweiz (DE)`,
    // ch_fr: t`Suisse (FR)`,
    int_en: t`Global English`,
    int_es: t`Global Spanish`,
  }
  const storeViewsCopy = [...originalStoreViews]

  const storeViewsWithLabel = storeViewsCopy
    .map((storeView) => ({
      ...storeView,
      label: labels[storeView.locale],
    }))
    .filter((storeView) => storeView.label)
    .sort((a, b) => a.label?.localeCompare(b.label))

  const globalStoreViews = storeViewsWithLabel.filter(
    (storeView) => storeView.websiteId === 3,
  )

  const regularStoreViews = storeViewsWithLabel.filter(
    (storeView) => storeView.websiteId !== 3,
  )

  const storeViews = [...regularStoreViews, ...globalStoreViews]

  return (
    <Label className={className}>
      <Pin aria-label={t`Select a store`} />

      <StoreViewSelect
        onChange={(e) => {
          if (!globalWindow) {
            return
          }
          globalWindow.location.href =
            storeViews.find((store) => store.code === e.currentTarget.value)
              ?.basename ?? ''
        }}
        defaultValue={defaultStoreViewCode}
      >
        {storeViews.map((storeView) => (
          <option key={storeView.code} value={storeView.code}>
            {labels[storeView.locale] ?? storeView.locale}
          </option>
        ))}
      </StoreViewSelect>
    </Label>
  )
}
